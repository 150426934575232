@import '../../../../scss/theme-bootstrap';

.sitewide-footer-formatter {
  margin: 0 auto 50px;
  @media #{$cr19-large-up} {
    margin: 0 auto 80px;
    padding: 0;
    width: 100%;
  }
  &__global {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 20px 0;
    @media #{$cr19-large-up} {
      margin: 0;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      right: 50px;
    }
  }
  &__recycling {
    &-text {
      @media #{$cr19-medium-up} {
        font-size: 16px;
      }
      a {
        color: $cr19-text;
      }
    }
    &-img {
      float: $ldirection;
      margin-#{$rdirection}: 5px;
      @media #{$cr19-large-up} {
        @include swap_direction(margin, -6px 5px 10px 0);
        position: relative;
        top: 7px;
      }
    }
    @media #{$cr19-large-up} {
      display: flex;
      justify-content: flex-end;
      width: 15%;
    }
  }
  &__regulatory-info,
  &__recycling,
  &__gateway {
    margin-#{$rdirection}: 20px;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    @media #{$cr19-large-up} {
      margin-top: 50px;
    }
  }
  &__bottom {
    @include text-body-text--no-xl; // catch-all for type sizes
    padding-bottom: 40px;
    @media #{$cr19-large-up} {
      margin-top: 50px;
      padding-bottom: 55px;
    }
    .sitewide-footer-formatter__bottom-wrapper {
      display: block;
      width: 100%;
      @media #{$cr19-large-up} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .sitewide-footer-formatter__variants {
        margin: 20px 0;
        @media #{$cr19-large-up} {
          margin: 0 3%;
          order: 2;
        }
        .sitewide-footer-formatter__accessibility {
          display: inline-block;
          text-align: center;
          width: 50%;
        }
        .sitewide-footer-formatter__trustmark {
          display: inline;
          text-align: center;
          visibility: visible;
          width: 50%;
        }
      }
      .sitewide-footer-formatter__country-chooser {
        @media #{$cr19-large-up} {
          font-size: 16px;
          margin-#{$ldirection}: 5%;
          order: 3;
        }
      }
      .sitewide-footer-formatter__language-toggle {
        @media #{$cr19-large-up} {
          font-size: 1rem;
          margin-#{$ldirection}: 10px;
          order: 4;
        }
      }
      .sitewide-footer-formatter__copyright {
        font-size: 1rem;
        margin: 30px 0 10px;
        @media #{$cr19-large-up} {
          flex: 1;
          margin: 0;
          order: 1;
        }
      }
    }
  }
  &__links {
    @include gnav-text-link;
    @include swap_direction(padding, 40px 25px 0 0);
    display: block;
    flex: 1 1 50%;
    margin: 0;
    width: auto;
    max-width: 50%;
    word-wrap: break-word;
    @media #{$cr19-large-up} {
      @include swap_direction(padding, 30px 20px 0 0);
      max-width: 100%;
      flex: 1 1 auto;
    }
    &:last-child {
      padding-#{$rdirection}: 0;
    }
    .footer-links__title {
      margin-bottom: 16px;
    }
    .footer-links__link {
      margin-bottom: 13px;
      .optanon-show-settings {
        z-index: 1;
      }
    }
    &.sitewide-footer-formatter__links-full-row {
      &:first-child {
        flex: 1 1 100%;
        max-width: unset;

        .text-link--style-2 {
          display: none;

          &:last-child {
            display: inline-flex;
          }
        }

        /* IMPORTANT:
          This is needed to override the incoming styling from LiveChat
          And WhatsApp async loaded buttons (HTML inserted with inline styling)
        */
        .LPMcontainer {
          display: inline-flex !important;
          > div > u {
            text-decoration: none;
            > a {
              -moz-transition: unset;
              -webkit-transition: unset;
              color: $cr19-text;
              font-family: $helvetica-regular-font-family;
              font-size: 1rem;
              font-weight: unset;
              letter-spacing: 0;
              line-height: 1.2;
              margin: 0;
              outline: unset;
              transition: 0.3s;
            }
          }
          .elc-button.button--dark {
            background-color: unset;
            border: unset;
            color: $cr19-text;
            cursor: unset;
            display: unset;
            font-family: $helvetica-regular-font-family;
            font-size: 1rem;
            height: unset;
            letter-spacing: 0;
            line-height: 1.2;
            margin: 0;
            text-align: unset;
            transition: 0.3s;
            width: unset;
          }
        }
        .footer-links-image__section > .footer-links-image__inline {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

body {
  // footer simplified on all checkout pages, including the cart page.
  &#viewcart,
  &#samples,
  &#index.active-panel-shipping,
  &#index.active-panel-payment,
  &#index.opc__shipping,
  &#index.opc__review,
  &#shipping,
  &#billing,
  &#payment,
  &#payment_select,
  &#review,
  &#confirm,
  &#index.checkout.elc-user-state-logged-in {
    .sitewide-footer-formatter {
      &__top,
      &__content,
      &__country-chooser {
        display: none;
      }
    }
  }
  &#index {
    &.checkout {
      &.active-panel-registration {
        .sitewide-footer-formatter {
          &__top,
          &__content,
          &__country-chooser {
            display: none;
          }
        }
      }
    }
  }
  .page-footer-region {
    clear: both;
  }
}
